import React, { useState, useRef, useEffect } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import emailjs from 'emailjs-com';

const HourlyBookingForm = ({ driverData }) => {
    function formatDateTime(dateTimeValue) {
        const date = new Date(dateTimeValue);
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        };
        return date.toLocaleDateString(undefined, options);
    }

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"],
    });

    const BookingSuccess = () => {
        return (
            <div className="max-w-md bg-green-200 mx-auto p-6 border border-green-400">
                <h2 className="text-lg font-semibold text-green-800 mb-2">
                    Booking Submitted Successfully
                </h2>
                <p>Your booking request has been successfully submitted.</p>
                <p>
                    We will reach out to you ASAP via text or call to confirm your
                    booking. Thank you!
                </p>
            </div>
        );
    };

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        pickupLocation: "",
        dateTime: "",
        specialInstructions: "",
        carType: "SUV",
        numPassengers: "1",
        paymentMethod: "pay-driver",
        totalCost: 70,
        numHours: 1,
    });

    const [showReview, setShowReview] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const pickupAutocomplete = useRef(null);

    const handlePlaceSelect = (place, field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: place.formatted_address,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (formData.numHours) {
            calculateCost();
        }
    }, [formData.numHours]);

    function calculateCost() {
        if (!formData.numHours) {
            return;
        }
        const cost = formData.numHours * 70;
        setFormData((prevData) => ({
            ...prevData,
            totalCost: cost,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const emailBody = `
        You have received a new booking request from a customer:

        Full Name: ${formData.fullName}
        Email: ${formData.email}
        Phone: ${formData.phone}
        Pickup Location: ${formData.pickupLocation}
        Date and Time: ${formData.dateTime}
        Special Instructions: ${formData.specialInstructions}
        Car Type: ${formData.carType}
        Number of Passengers: ${formData.numPassengers}
        Payment Method: ${formData.paymentMethod}
        Total Cost: $${formData.totalCost}
        Number of Hours: ${formData.numHours}

        Best wishes,
        Honestlimo.com
      `;

            const emailParams = {
                to_email: driverData.email,
                message: emailBody,
                to_name: driverData.name,
                reply_to: formData.email,
                from_name: formData.fullName
            };

            await emailjs.send(
                "default_service",
                "template_mmeyh3u",
                emailParams,
                process.env.REACT_APP_EMAILJS_USER_ID
            );

            setFormData({
                fullName: "",
                email: "",
                phone: "",
                pickupLocation: "",
                dateTime: "",
                specialInstructions: "",
                carType: "SUV",
                numPassengers: "1",
                paymentMethod: "pay-driver",
                totalCost: 70,
                numHours: 1,
            });
            setShowReview(false);
            setIsSubmitted(true);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleReviewClick = () => {
        setShowReview(true);
    };

    const SummaryComponent = () => {
        return (
            <div>
                <h2 className="text-lg font-semibold mb-2">Review Your Booking</h2>
                <p>
                    <strong>Total Cost:</strong> ${formData.totalCost.toFixed(2)}
                </p>
                <p>
                    <strong>Time:</strong> {formatDateTime(formData.dateTime)}
                </p>
                <p>
                    <strong>Pickup Location:</strong> {formData.pickupLocation}
                </p>
                <p>
                    <strong>Car Type:</strong> {formData.carType}
                </p>
                <p>
                    <strong>Number of Passengers:</strong> {formData.numPassengers}
                </p>
                <p>
                    <strong>Payment Method:</strong> {formData.paymentMethod}
                </p>
                <p>
                    <strong>Special Instructions:</strong> {formData.specialInstructions || "N/A"}
                </p>
                <p>
                    <strong>Full Name:</strong> {formData.fullName}
                </p>
                <p>
                    <strong>Email:</strong> {formData.email}
                </p>
                <p>
                    <strong>Phone:</strong> {formData.phone}
                </p>

                <button
                    type="button"
                    onClick={() => setShowReview(false)}
                    className="inline-block mt-2 text-blue-500 hover:text-blue-700"
                >
                    Edit Ride
                </button>
                <div className="mt-2">
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Book Ride
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div
            id="booking"
            className="max-w-md bg-base-200 mx-auto p-6 border border-gray-300"
        >
            {isSubmitted ? (
                <BookingSuccess />
            ) : showReview ? (
                <SummaryComponent />
            ) : (
                <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                    <div className="mb-4">
                        <label
                            htmlFor="pickupLocation"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Pickup Location
                        </label>
                        {isLoaded && (
                            <Autocomplete
                                onLoad={(autocomplete) => {
                                    pickupAutocomplete.current = autocomplete;
                                    autocomplete.setFields(["formatted_address"]);
                                }}
                                onPlaceChanged={() =>
                                    handlePlaceSelect(
                                        pickupAutocomplete.current.getPlace(),
                                        "pickupLocation"
                                    )
                                }
                            >
                                <input
                                    type="text"
                                    id="pickupLocation"
                                    name="pickupLocation"
                                    value={formData.pickupLocation}
                                    onChange={handleInputChange}
                                    className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                />
                            </Autocomplete>
                        )}
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="dateTime"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Date and Time
                        </label>
                        <input
                            type="datetime-local"
                            id="dateTime"
                            name="dateTime"
                            value={formData.dateTime}
                            onChange={handleInputChange}
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="numHours"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Number of Hours
                        </label>
                        <input
                            type="number"
                            id="numHours"
                            name="numHours"
                            value={formData.numHours}
                            onChange={handleInputChange}
                            min="1"
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="carType"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Car Type
                        </label>
                        <select
                            id="carType"
                            name="carType"
                            value={formData.carType}
                            onChange={handleInputChange}
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="SUV">SUV</option>
                            <option value="Sedan">Sedan</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="numPassengers"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Number of Passengers
                        </label>
                        <input
                            type="number"
                            id="numPassengers"
                            name="numPassengers"
                            value={formData.numPassengers}
                            onChange={handleInputChange}
                            min="1"
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="paymentMethod"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Payment Method
                        </label>
                        <select
                            id="paymentMethod"
                            name="paymentMethod"
                            value={formData.paymentMethod}
                            onChange={handleInputChange}
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="pay-driver">Pay Driver</option>
                            <option value="pay-now">Pay Now</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="specialInstructions"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Special Instructions
                        </label>
                        <textarea
                            id="specialInstructions"
                            name="specialInstructions"
                            value={formData.specialInstructions}
                            onChange={handleInputChange}
                            rows="4"
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        ></textarea>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="fullName"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Full Name
                        </label>
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Phone
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            className="mt-1 p-2 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-1">
                        <p

                            className="block text-sm font-medium text-gray-700"
                        >
                            <strong>Total Hours:</strong> {formData.numHours}
                        </p>

                    </div>
                    <div className="mb-2">
                        <p

                            className="block text-sm font-medium text-gray-700"
                        >
                            <strong>Total Cost:</strong> ${formData.totalCost.toFixed(2)}
                        </p>
                    </div>
                    {showReview ? (
                        <div>
                            <SummaryComponent />
                        </div>
                    ) : (
                        <button
                            type="button"
                            onClick={handleReviewClick}
                            className="inline-block mt-4 text-blue-600 hover:text-blue-700"
                        >
                            Review Booking
                        </button>
                    )}
                    <div className="mt-6">
                        <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Book Ride
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default HourlyBookingForm;
