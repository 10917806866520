import React, { useState } from "react";
import BookingForm from "./BookingForm";
import HourlyBookingForm from "./HourlyBookingForm";

function HonestLimo() {
  const driverData = {
    name: "HonestLimo",
    phoneNumber: "952-652-3891",
    email: "honestlimoservice@gmail.com",
    costPerMile: 4.25,
    costPerMileSedan: 3.75,
    minRideCost: 70,
    startCost: 15,
  };
  const [isHourlyBooking, setIsHourlyBooking] = useState(false);

  const handleCallButtonClick = () => {
    window.location.href = "tel:9526523891";
  };

  const scrollToBookingForm = () => {
    const bookingForm = document.getElementById("bookingFormSection");
    if (bookingForm) {
      window.scrollTo({
        top: bookingForm.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="min-h-screen">
      <header className="bg-base-200">
        <nav className="p-4 flex flex-col sm:flex-row justify-between items-center">
          <div className="flex items-center mb-4 sm:mb-0">
            <img
              src={require("./logo.png")}
              alt="Honest Limo Logo"
              className="h-10 w-10 mr-2"
            />
            <div className="text-xl font-bold">Honest Limo</div>
          </div>
          <div className="hidden sm:flex flex-col sm:flex-row gap-2 sm:gap-4">
            <button
              onClick={handleCallButtonClick}
              className="btn btn-primary mb-2 sm:mb-0"
            >
              Call {driverData.phoneNumber}
            </button>
            <button
              onClick={scrollToBookingForm}
              className="btn btn-secondary"
            >
              Book Now
            </button>
          </div>
        </nav>
      </header>

      <section className="max-w-7xl mx-auto bg-base-100 flex flex-col sm:flex-row items-center justify-center gap-6 sm:gap-10 px-4 py-6 sm:py-10">
        <div className="flex flex-col gap-4 sm:gap-6 items-center justify-center text-center sm:text-left sm:items-start">
          <h1 className="font-extrabold text-5xl sm:text-4xl tracking-tight mb-2">
            Experience Luxury Travel in Every Ride
          </h1>
          <p className="text-base sm:text-lg opacity-80 leading-relaxed">
            Arrive in Style, Comfort, and Elegance with Honest Limo – Your Premier Chauffeur Service.
          </p>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
            <button
              onClick={handleCallButtonClick}
              className="btn btn-primary"
            >
              Call {driverData.phoneNumber}
            </button>
            <button
              onClick={scrollToBookingForm}
              className="btn btn-secondary"
            >
              Book Your Exclusive Ride Today
            </button>
          </div>
        </div>
        <div className="w-full sm:w-2/3">
          <img
            src={"https://simonslimo.com/wp-content/uploads/elementor/thumbs/2018-chevrolet-suburban-101-1531774571-1-qkf7pczgmkbrmm05uos181djqsmajzzt0v95wyazvk.jpg"}
            alt="Product Demo"
            className="w-full rounded-lg"
          />
        </div>
      </section>

      <section className="bg-base-300">
        <div className="p-4">
          <div className="container mx-auto">
            <h2 className="text-center text-xl sm:text-2xl font-bold mb-4">
              Our Exclusive Services
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6">
              {/* Service Card 1 */}
              <div className="card bg-white rounded-lg shadow-md flex flex-col">
                <div
                  className="h-40 sm:h-48 w-full bg-cover bg-center rounded-t"
                  style={{
                    backgroundImage: `url(https://simons.limo/wp-content/uploads/2022/11/chauffeur-standing-by-the-vehicle-with-the-open-bo-2021-09-03-09-42-18-utc.jpg)`,
                  }}
                ></div>
                <div className="p-4 flex-grow">
                  <h2 className="text-lg sm:text-xl font-semibold mb-2">
                    Airport Transfers Made Effortless
                  </h2>
                  <p className="text-gray-700">
                    Relax and Enjoy Top-Quality Chauffeured Airport Transportation. Your Journey Begins with Us.
                  </p>
                </div>
              </div>

              {/* Service Card 2 */}
              <div className="card bg-white rounded-lg shadow-md flex flex-col">
                <div
                  className="h-40 sm:h-48 w-full bg-cover bg-center rounded-t"
                  style={{
                    backgroundImage: `url(https://simons.limo/wp-content/uploads/2022/11/two-businesspeople-sitting-inside-car-using-electronic-devices-3874150-1024x683-1.jpg)`,
                  }}
                ></div>
                <div className="p-4 flex-grow">
                  <h2 className="text-lg sm:text-xl font-semibold mb-2">
                    Efficient Corporate Travel Solutions
                  </h2>
                  <p className="text-gray-700">
                    Elevate Your Business Trips with Our Stress-Free Corporate Transportation. Punctuality is Our Commitment.
                  </p>
                </div>
              </div>

              {/* Service Card 3 */}
              <div className="card bg-white rounded-lg shadow-md flex flex-col">
                <div
                  className="h-40 sm:h-48 w-full bg-cover bg-center rounded-t"
                  style={{
                    backgroundImage: `url(https://simons.limo/wp-content/uploads/2022/11/happy-couple-transporting-via-a-car-2022-09-16-07-34-30-utc.jpg)`,
                  }}
                ></div>
                <div className="p-4 flex-grow">
                  <h2 className="text-lg sm:text-xl font-semibold mb-2">
                    Event Leisure in Ultimate Style
                  </h2>
                  <p className="text-gray-700">
                    Make Your Events Memorable with Our Premium Limo Service. Arrive at Your Destination with Unmatched Style.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* "Why Choose Us" Section */}
      <section className="bg-base-100 py-8 sm:py-12">
        <h2 className="text-center text-xl sm:text-2xl font-bold mb-4">
          Why Choose Honest Limo
        </h2>
        <p className="text-center mb-6 sm:mb-8">
          We Ensure Every Journey is a Memorable Experience.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6">
          <div className="border p-6 text-center rounded-lg shadow-lg bg-white">
            <div className="mb-4">
              <div className="text-2xl font-bold mb-2">🚗</div>
              <h3 className="text-lg font-semibold mb-2">PROFESSIONAL SERVICES</h3>
              <p>
                We provide seamless limo transportation service to give you a first-class ground trip experience.
              </p>
            </div>
          </div>
          <div className="border p-6 text-center rounded-lg shadow-lg bg-white">
            <div className="mb-4">
              <div className="text-2xl font-bold mb-2">🛡️</div>
              <h3 className="text-lg font-semibold mb-2">SAFETY FIRST APPROACH</h3>
              <p>
                Your safety is our priority. Our limos are always in great condition, and our chauffeurs are well-trained.
              </p>
            </div>
          </div>
          <div className="border p-6 text-center rounded-lg shadow-lg bg-white">
            <div className="mb-4">
              <div className="text-2xl font-bold mb-2">📅</div>
              <h3 className="text-lg font-semibold mb-2">EASY BOOKING</h3>
              <p>
                Book your memorable experience with us via our fast online booking form, Call, or send SMS.
              </p>
            </div>
          </div>
          <div className="border p-6 text-center rounded-lg shadow-lg bg-white">
            <div className="mb-4">
              <div className="text-2xl font-bold mb-2">🔧</div>
              <h3 className="text-lg font-semibold mb-2">RELIABILITY</h3>
              <p>
                We take the hassle off your neck and ensure that you or your guests arrive at the destination in comfort.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-base-300 py-8 sm:py-12 text-center"
        id="bookingFormSection"
      >
        <h2 className="text-center text-xl sm:text-2xl font-bold mb-4">
          MAKE YOUR RESERVATION NOW
        </h2>
        <div className="mb-4">
          <label className="block text-lg font-medium text-black">
            Booking Type
          </label>
          <div className="mt-1">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="bookingType"
                value="one-way"
                checked={!isHourlyBooking}
                onChange={() => setIsHourlyBooking(false)}
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              />
              <span className="ml-2">Distance</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="bookingType"
                value="hourly"
                checked={isHourlyBooking}
                onChange={() => setIsHourlyBooking(true)}
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              />
              <span className="ml-2">Hourly</span>
            </label>
          </div>
        </div>
        {isHourlyBooking ? (
          <HourlyBookingForm driverData={driverData} />
        ) : (
          <BookingForm driverData={driverData} />
        )}
      </section>

      <footer className="bg-base-100 text-white py-6 sm:py-8 text-center">
        <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
          <div className="flex items-center mb-2 sm:mb-0">
            <img
              src={require("./logo.png")}
              alt="Private Limo Logo"
              className="h-10 w-10 mr-2"
            />
            <div className="text-lg text-black font-bold">Honest Limo</div>
          </div>
          <div className="mt-2 text-black sm:mt-0">
            <p>
              © {new Date().getFullYear()} Honest Limo. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HonestLimo;
